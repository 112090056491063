import React, { useState, useMemo, useEffect } from "react"
import {
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Container,
  Row,
} from "react-bootstrap"
import axios from "axios"
import {
  AUTH_TOKEN_KEY,
  URL_GETALL_ADVANTAGES,
  translate,
  getUserLanguage,
  KEY_LANGUAGE_USER,
  KEY_LANGUAGE_BROWSER,
  DOMAIN_API,
  URL_GETALL_VAL,
  URL_TAKE_ADVANTAGE_USER,
  URL_GETALL,
  URL_GETALL_ADVANTAGE_PARTNERS,
} from "../utils"
import AdvantageModal from "../components/modalAdvantage"
import Layout from "../components/layout"
import ModalPartnerUnselected from "../components/modalPartnerUnselected"
import { Button } from "react-bootstrap"
import backgroundplus from "../images/deal-swipe-D.png"
import focusD from "../images/focus-D.png"
import backgroundplus1 from "../images/deal-swipe-D1.png"

const alreadyRemoved = []

function Test() {
  const [advantage, setadvantage] = useState([])
  const [sumAdv, setSumAdv] = useState(0)
  const [loader, setLoader] = useState(false)
  const [showAdvantageModal, setshowAdvantageModal] = useState(false)
  const [activeAdvantage, setactiveAdvantage] = useState(undefined)
  const [
    idPartnerForModalPartnerUnselected,
    setidPartnerForModalPartnerUnselected,
  ] = useState(null)
  const [activeView, setactiveView] = useState("gridview")
  const [optionChoosed, setoptionChoosed] = useState("")
  const [advSelected, setAdvSelected] = useState([])
  var langs = getUserLanguage()

  useEffect(() => {
    getAll()
  }, [])

  const getAdvantage = (id, val, symbole) => {
    if (advSelected.includes(id)) {
      let arr = advSelected.filter(el => el != id)
      setAdvSelected([...arr])
      if (val && symbole == "€") setSumAdv(sumAdv - val * 1)
    } else {
      setAdvSelected([...advSelected, id])
      if (val && symbole == "€") setSumAdv(sumAdv + val * 1)
    }
  }
  const toggleAdvantageModal = item => {
    setshowAdvantageModal(!showAdvantageModal)
    if (!showAdvantageModal) {
      setactiveAdvantage(item)
    } else {
      setactiveAdvantage(undefined)
    }
  }

  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const getAlladvantage = () => {
    setLoader(true)
    advSelected.forEach(element => {
      takeAdvantage(element)
      sleep(500)
    })
    sleep(200)
    setLoader(false)
    window.location.href = "/feed"
  }

  const takeAdvantage = id => {
    if (id) {
      const data = {
        id: id,
      }
      console.log(data)
      const token = localStorage.getItem(AUTH_TOKEN_KEY)
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      }
      axios
        .post(URL_TAKE_ADVANTAGE_USER, data, header)
        .then(res => {
          console.log("Succes")
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const getAll = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    await axios
      .get(URL_GETALL_ADVANTAGE_PARTNERS)
      .then(res => {
        if (res.data) {
          setadvantage(res.data)
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err)
        }
      })
  }

  return (
    <Layout ClassIndex="pa-pagecontent desktop-page">
      <div>
        <Container fluid>
          <Row className="headerSec align-items-end">
            <Col lg="1" className="pl-0 pr-0"></Col>
            <Col lg="2" className="pl-0 pr-0">
              {" "}
              <img className="focusD" src={focusD} />
            </Col>
            <Col lg="6">
              <p className="text-white  hpd  mb-0">
                {" "}
                {translate(
                  "Voici des avantages et des deals qui devraient vous intéresser"
                )}
                <br />
                {translate(
                  "Pour en profiter, selectionner sans engagement les deals qui vous plaisent et nos partenires vous ccontacteront pour en beneficer!"
                )}
              </p>
              
              
              {langs == "FR"? 
                <h2 className="h1f18 text-right text-white align-bottom pb-0 mb-0">
                  {translate("VOUS AVEZ SÉLECTIONNÉ DES DEALS POUR UNE VALEUR DE")}
                  <span className="text-success">
                    {sumAdv} <b>€</b>
                  </span>
                </h2>
                :
                <h2 className="h1f18 text-right text-white align-bottom pb-0 mb-0">
                {translate("U HEEFT VOOR ")}
                <span className="text-success">
                  {sumAdv} <b>€</b>
                </span>
                {translate(" AAN DEALS GEKOZEN")}
                </h2>
                }
              
            </Col>
            <Col lg="3">
              <div className="buttons Swpb d-block pb-1 m-0">
                {loader ? (
                  <div class="spinner-border text-dark" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <button
                    onClick={getAlladvantage}
                    className="btn btn-sm clearfix mb-1  bf14"
                  >
                    {translate("Continuez")}
                  </button>
                )}
              </div>
            </Col>
            <Col lg="1" className="pl-0 pr-0"></Col>
          </Row>
        </Container>
        <Container className="SwpD">
          <Row>
            {advantage.map(item => {
              return (
                <Col key={item.id} lg="4" md="6" sm="6" className="mb-2 colD">
                  <Card className="card-ch-t">
                    <Card.Body className="card-body-ch">
                      <div className="position-relative w95">
                        <div
                          className={`row zk zkP  ${
                            advSelected.includes(item.id) ? "advselected" : ""
                          }`}
                        >
                          <div className="col-md-4 text-center pl-0 pr-0 zk1">
                            <img
                              onClick={() => {
                                toggleAdvantageModal(item)
                              }}
                              src={DOMAIN_API() + item.avatarImg}
                            />
                          </div>
                          <div className="col-md-8 f12 zk2">
                            <div
                              onClick={() => {
                                toggleAdvantageModal(item)
                              }}
                              className=" pt-2 pb-2 pr-4 pl-4 "
                            >
                              {langs == "FR"
                                ? item.contentFR
                                  ? item.contentFR.slice(0, 60) + "..."
                                  : ""
                                : item.contentNL
                                ? item.contentNL.slice(0, 60) + "..."
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-md-4"></div>
                          <div className=" col-md-8 text-right splus">
                            <label
                              className="spluslabel pointerc text-right"
                              onClick={() => {
                                getAdvantage(item.id, item.val, item.symbole)
                              }}
                            >
                              {translate(
                                "Je sélectionne ce deal"
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            getAdvantage(item.id, item.val, item.symbole)
                          }}
                          className="fleshplus fleshplus2"
                          style={{
                            backgroundImage: advSelected.includes(item.id)
                              ? `url(${backgroundplus1})`
                              : `url(${backgroundplus})`,
                          }}
                        >
                          {item.val ? (
                            <span>
                              <b>{translate("votre avantage")}</b>
                              <br />
                              {item.val} <label>{item.symbole}</label>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
      {showAdvantageModal ? (
        <AdvantageModal
          show={showAdvantageModal}
          toggle={toggleAdvantageModal}
          advantage={activeAdvantage}
        />
      ) : (
        ""
      )}
    </Layout>
  )
}

export default Test
